import { apiSlice } from "./apiSlice"

const apiWithTag = apiSlice.enhanceEndpoints({addTagTypes: ['User']})

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		postLogout: build.mutation({
			query: () => ({
				url: '/logout',
				method: 'POST',
				body: {}
			})
		}),
		postSocialProviderDisconnect: build.mutation({
			query: (data) => ({
				url: '/settings/social/disconnect',
				method: 'POST',
				body: {
					provider: data.provider
				}
			}),
			invalidatesTags: ['User']
		}),
		getMe: build.query({
			query: () => ({url: '/me', method: 'get' }),
			providesTags: (result, error, arg) => [{ type: 'User', id: result?.id }]
		}),
		getMeCheck: build.query({
			query: () => ({url: '/me/check', method: 'get' }),
		})
	}),
	overrideExisting: false,
})

export const {
	usePostLoginMutation,
	usePostSocialProviderDisconnectMutation,
	useGetMeQuery,
	useGetMeCheckQuery,
	usePostLogoutMutation,
	util: { getRunningQueriesThunk }
} = extendedApi

export const {
	getMe,
	getMeCheck
} = extendedApi.endpoints;
