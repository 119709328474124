import { create } from "zustand";

export const useGeneralStore = create((set) => ({
	
	contentMenu: false,
	toggleContentMenu: () => set((state) => ({ contentMenu: !state.contentMenu})),
	
	showGlobalLoading: false,
	setShowGlobalLoading: (showGlobalLoading) => set({ showGlobalLoading }),
	
	globalMessage: false,
	setGlobalMessage: (globalMessage) => set({ globalMessage }),
	
	isContentDragging: false,
	setIsContentDragging: (isContentDragging) => set({ isContentDragging }),
	
	coolText: false,
	setCoolText: (coolText) => set({ setCoolText })
}))
