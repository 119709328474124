import React from 'react';
import { TourProvider } from '@reactour/tour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export function TourWrapper({ children }) {

	return (
		<TourProvider
			steps={[]}
			afterOpen={(e) => disableBodyScroll(e)}
			beforeClose={(e) => enableBodyScroll(e)}
			prevButton={({ currentStep, setCurrentStep, steps }) => {
				const first = currentStep === 0
				return (
					<button
						onClick={() => {
							if (first) {
								setCurrentStep((s) => steps.length - 1)
							} else {
								setCurrentStep((s) => s - 1)
							}
						}}
					>Back</button>
				)
			}}
			nextButton={({ Button, currentStep, stepsLength, setIsOpen, setCurrentStep, steps }) => {
				const last = currentStep === stepsLength - 1
				return (
					<Button
						hideArrow={last}
						onClick={() => {
							if (last) {
								setIsOpen(false)
							} else {
								setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1))
							}
						}}
					>{last ? 'Close' : null}</Button>
				)
			}}
		>
			{children}
		</TourProvider>
	);
}

export default TourWrapper;
