import {useGetMeCheckQuery} from "../rtk-api/auth";
import Bugsnag from "@bugsnag/js";
import TagManager from "react-gtm-module"

const AuthProvider = ({ children }) => {
	const {data:meInfo} = useGetMeCheckQuery()
	
	if (meInfo && meInfo.is_logged_in == false) {
		let redirect = process.env.NEXT_PUBLIC_AUTH_URL + '/login'
		if (window !== undefined) {
			const nextLocation = window.location.href
			
			const slugsToExclude = ['401', '403', '404', ''];
			if (slugsToExclude.every(slug => !nextLocation.includes(slug))) {
				redirect = redirect + '?next=' + encodeURIComponent(nextLocation)
			}
		}
		window.location.href = redirect
	} else if (meInfo && meInfo.is_logged_in) {
		
		Bugsnag.setUser(meInfo.id, meInfo.email)
		TagManager.dataLayer({
			dataLayer: {
				userId: meInfo.id,
			}
		})
		
		return (
			children
		);
	}
}

export default AuthProvider;
