import React, {useEffect} from 'react'
import '../styles/globals.css'
import "../styles/rsuite.css";
import 'animate.css'
import {wrapper} from "../store";
import {Provider} from "react-redux";
import {TourWrapper} from '../providers/tourprovider';
import AuthProvider from "../providers/authprovider";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import NextNProgress from 'nextjs-progressbar';
import {DefaultSeo} from "next-seo";
import TagManager from "react-gtm-module"

Bugsnag.start({
	apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
	releaseStage: process.env.NEXT_PUBLIC_RELEASE_STAGE,
	enabledReleaseStages: [ 'production', 'staging' ],
	plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const MyApp = ({Component, ...rest}) => {
	const {store, props} = wrapper.useWrappedStore(rest);
	
	useEffect(() => {
		if (process.env.NEXT_PUBLIC_GTM_ID) {
			TagManager.initialize({
				gtmId: process.env.NEXT_PUBLIC_GTM_ID,
				auth: process.env.NEXT_PUBLIC_GTM_AUTH,
				preview: process.env.NEXT_PUBLIC_GTM_ENV
			})
		}
	}, []);
	
	return (
		<ErrorBoundary>
			<>
				<NextNProgress
					color="#b438e0"
					height={4}
				/>
				<Provider store={store}>
					<AuthProvider>
						<TourWrapper>
							<>
								<DefaultSeo
									dangerouslySetAllPagesToNoFollow={true}
									dangerouslySetAllPagesToNoIndex={true}
								/>
								<Component {...props.pageProps} />
							</>
						</TourWrapper>
					</AuthProvider>
				</Provider>
			</>
		</ErrorBoundary>
	);
}

export default MyApp;
