import { isRejectedWithValue } from '@reduxjs/toolkit'
import {useGeneralStore} from "../stores/general";

export const queryErrorLogger = (api) => (next) => (action) => {
	if (isRejectedWithValue(action)) {
		switch(action.payload.status) {
			case 401:
			case 403:
				useGeneralStore.setState({globalMessage: {
					warning: true,
					message: action.payload.data.message ?? 'You are not allowed to perform this action'
				}})
				break;
			case 404:
				useGeneralStore.setState({globalMessage: {
					warning: true,
					message: '404 not found'
				}})
				break;
			case 429:
				useGeneralStore.setState({globalMessage: {
					warning: true,
					message: 'Please try again in a few minutes'
				}})
				break;
			case 500:
			case 501:
			case 502:
			case 503:
				
				let message = 'There was an issue and we have been alerted. Please try again later.'
				if (action.payload?.data?.message != 'Server Error') {
					message = action.payload?.data?.message
				}
				
				useGeneralStore.setState({globalMessage: {
					error: true,
					message
				}})
				break;
			default:
				break;
		}
	}
	return next(action)
}
